import $ from 'jquery';
import 'slick-carousel';
import 'bootstrap';

import '../styles/index.scss';

('use strict');

function getAffLink() {
    var affLink = 'https://chrome.google.com/webstore/detail/yahoo-sports-new-tab/adcckmmmfnjeegbjkdnopgbghngopkhi?extInstall=1&partner=spor-reachmobi&campaign=';
    var urlParams = new URLSearchParams(window.location.search);
    var utmSource;
    var placement;
    var campaign;
    
    if (urlParams) {
        utmSource = urlParams.get('utm_source');
        placement = urlParams.get('placement');
        campaign = urlParams.get('campaign');

        if (utmSource) {
            affLink = affLink + 'utmsource:' + utmSource + '_';
        }

        if (placement) {
            affLink = affLink + 'placement:' + placement + '_';
        }

        if (campaign) {
            affLink = affLink + 'campaign:' + campaign + '_';
        }
    }
    
    affLink = affLink + 'D_16-(d)';

    return affLink;
}

$(document).ready(function() {  
    getAffLink();
    $('.slider')
        .slick({
            dots: true,
        })
        .css('opacity', 1);

    $('.storeLink').click(function() {
        if ('function' === typeof gtag) {
            gtag('event', 'CTA Click', {
                'event_category': 'Button',
                'event_label': 'Store Link'
            });
        }
        window.location.href = getAffLink();
    });
});
